.radio---control-radio---2rwkY {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.radio---control-radio---2rwkY *,
.radio---control-radio---2rwkY *:before,
.radio---control-radio---2rwkY *:after {
  box-sizing: inherit;
}
.radio---control-radio---2rwkY.radio---disabled---eCY9b {
  opacity: 0.4;
  cursor: not-allowed;
}
.radio---control-radio---2rwkY .radio---control-indicator---dcwXW {
  position: relative;
  display: flex;
  align-items: center;
  pointer-events: none;
  min-width: 22px;
}
.radio---control-radio---2rwkY .radio---text-label---2D19D {
  display: inline-block;
}
.radio---input-radio---2yM_b {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}
.radio---input-radio---2yM_b + .radio---control-indicator---dcwXW::before {
  content: "";
  display: inline-block;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 50%;
  margin-right: 8px;
  width: 14px;
  height: 14px;
  vertical-align: top;
}
.radio---input-radio---2yM_b:checked + .radio---control-indicator---dcwXW::before {
  border: 1px solid #0096cc;
  background-color: #0096cc;
  transition: background-color 0.2s;
}
.radio---input-radio---2yM_b:checked + .radio---control-indicator---dcwXW::after {
  content: "";
  position: absolute;
  top: calc(50% - 3px);
  left: 4px;
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  transition: background-color 0.2s;
}
.radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover > .radio---control-indicator---dcwXW::before {
  border: 1px solid #0096cc;
}
.radio---control-radio---2rwkY:not(.radio---disabled---eCY9b):hover > .radio---input-radio---2yM_b:checked + .radio---control-indicator---dcwXW::before {
  border: 1px solid #33abd6;
  background-color: #33abd6;
}
.radio---input-radio---2yM_b:not(:checked):disabled {
  cursor: not-allowed;
}
.radio---input-radio---2yM_b:not(:checked):disabled + .radio---control-indicator---dcwXW::before {
  border: 1px solid #999;
  background-color: #ddd;
}
